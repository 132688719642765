.reset-password-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .reset-password-container h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
    color: #333;
  }
  
  .alert {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    color: #fff;
    text-align: center;
  }
  
  .alert-success {
    background-color: #4caf50;
  }
  
  .error-message {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    color: #fff;
    background-color: #f44336;
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  form div {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  button {
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  