/* Profile.css */

body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f4f7f6;
    margin: 0;
    padding: 0;
  }
  
  .profile-container {
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f0a10d;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-header h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 0.5em;
  }
  
  .profile-header p {
    color: #777;
    margin-bottom: 1em;
  }
  
  
  .upload-label {
    display: inline-block;
    cursor: pointer;
    color: #007bff;
    padding: 8px 12px;
    border: 1px solid #007bff;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .upload-label:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .profile-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .profile-fields label {
    flex: 1 1 45%;
    display: block;
    margin-bottom: 10px;
  }
  
  .profile-fields input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    transition: border-color 0.3s ease;
  }
  
  .profile-fields input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .button-container {
    text-align: center;
    margin-top: 30px;
  }
  
  button[type="submit"] {
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .profile-fields {
      flex-direction: column;
    }
  
    .profile-fields label {
      flex: 1 1 100%;
    }
  }
  