/* EnhancedLanding.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #667eea 0%, #a2514b 100%);
}

.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.form-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 100%;
  max-width: 500px;
  transition: all 0.3s ease;
}

.form-container.signup {
  max-width: 700px; /* Increased to accommodate side-by-side inputs */
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.form-header h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 10px;
}

.form-header p {
  color: #666;
  font-size: 14px;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.toggle {
  width: 60px;
  height: 30px;
  background-color: #ddd;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle.login {
  background-color: #4CAF50;
}

.toggle.signup {
  background-color: #2196F3;
}

.toggle-button {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle.signup .toggle-button {
  transform: translateX(30px);
}

.toggle-label {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

/* New styles for side-by-side inputs */
.input-row {
  display: flex;
  gap: 20px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
  flex: 1;
}

.input-group input {
  width: 100%;
  padding: 12px 10px;
  border: none;
  border-bottom: 2px solid #ddd;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
}

.input-group label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;
}

.input-group input:focus,
.input-group input:valid {
  border-bottom-color: #4CAF50;
}

.input-group input:focus + label,
.input-group input:valid + label {
  top: -20px;
  font-size: 12px;
  color: #4CAF50;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #45a049;
}

.forgot-password,
.switch-mode {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.forgot-password:hover,
.switch-mode span {
  color: #4CAF50;
  cursor: pointer;
}

.toggle-label{
  font-size: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-container {
  animation: fadeIn 0.5s ease-out;
}

/* Adjusted media query for smaller screens */
@media (max-width: 768px) {
  .form-container {
    padding: 20px;
    max-width: 100%;
  }

  .form-container.signup {
    max-width: 100%;
  }
  .form-container.login {
    width: 300px;
    height: 400px;
  }

  .input-row {
    flex-direction: column;
    gap: 0;
  }

  .form-header h2 {
    font-size: 24px;
    margin-bottom: -5%;
  }

  .input-group input {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 14px;
  }
}

/* New styles for better spacing in signup form */
.form-container.signup .input-group {
  margin-bottom: 20px;
}

.form-container.signup .submit-btn {
  margin-top: 5px;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}