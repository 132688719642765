* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(210deg, #FF5733, #29ABCA);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: Arial, sans-serif;
  padding: 20px;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.welcome-message {
  font-size: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.h1 {
  font-family: 'Gotham';
}
.intro-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.profile-pic {
  width: 75px;
  height:75px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}


.card {
  background-color: rgb(255, 140, 0); /* Lighter shade of logout button orange */
  border-radius: 10px;
  padding: 20px;
  width: 200px; /* Increased width */
  height: 150px; /* Set height for uniform rectangles */
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff; /* White text color for better contrast */
  font-size: 2rem;
  font-weight: 500;
}

.card:hover {
  transform: translateY(-5px);
  background-color: rgb(255, 0, 0); /* Slightly darker on hover */
}

.search-btn {
  background-color: #007bff;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 10px 20px; /* Adjusted padding */
  margin: 20px auto; /* Center the button */
  display: inline-block; /* Allow the button to shrink */
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #ff9a17;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.form-horizontal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  text-align: center;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
}

.form-group select,
.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ff0000;
  border-radius: 8px;
  background-color: #3cc1f6;
  color: #030303;
  transition: all 0.3s ease;
}

.form-group select {
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #333 50%),
                    linear-gradient(135deg, #333 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
                       calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.form-group select:hover,
.form-group input:hover,
.form-group select:focus,
.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.btn-custom {
  background-color: #007bff;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  padding: 15px;
  margin-top: 10px;
  width: fit-content;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.btn-custom:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.logout-btn {
  background: linear-gradient(to right, #FF4500, #FF8C00);
  border: none;
  color: #ffffff;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.2rem;
  transition: background 0.3s ease, transform 0.1s ease;
}

.logout-btn:hover {
  background: linear-gradient(to right, #FF6347, #FFA500);
  transform: scale(1.05);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@media (max-width: 600px) {
  
    .form-horizontal .form-group {
    flex: 1 1 100%;
  }
  .welcome-message{
    font-family: 'Gotham';
    padding: 30px;
    margin-top: -70%;
  }
  .profile-pic {
    width: 60px;
    height: 60px;
    border: 1px solid #ffffff;
    margin-left: 110px;

  }

  .card {
    font-size: medium;
    font-weight: 500;
    width: calc(50% - 50px);
    height: 60px; /* Adjusted for smaller screens */
  }
}