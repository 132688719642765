/* Combined CSS for Footer and Header */

/* Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c3e50;
  padding: 0.1rem;
  text-align: center;
  z-index: 1000;
}

.footer p {
  color: #ecf0f1;
  margin: 0;
  font-size: 14px;
}

.feedback-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.feedback-form {
  width: 90%;
  max-width: 500px;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #2c3e50;
}

.feedback-form h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
}

.feedback-form textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  background-color: #e74c3c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #c0392b;
}

/* Media For Footer */
/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .feedback-button {
    bottom: 30px; /* Adjust this value as needed */
    right: 5px;
    font-size: 2px;
    padding: 1px 1px;
  }

  .footer {
    padding: 0.2rem;
  }

  .footer p {
    font-size: 12px;
  }
}

/* Header styles */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  color: black; /* Text color */
  position: relative;
}

.header-title {
  font-size: 1.2rem; /* Adjust font size for header title */
  text-align: center;
  margin: 0;
  
}
.sidebar {
  position: fixed;
  top: 0;
  left: -75%;
  width: 75%;
  height: 8%;
  background-color: #0ed2f4;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
  height: fit-content;
  width: 380px;
}
.close-sidebar {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.sidebar-toggle {
  position: absolute;
  left: 1rem;
  top: 0.7rem;
  align-items: center;
  padding:0 5px 0 5px;
  background: wheat;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.sidebar-menu {
  list-style-type: none;
  padding: 2rem;
}

.sidebar-menu li {
  margin-bottom: 1rem;
  cursor: pointer;
}
.sidebar {
  left: -300px;
  width: 300px;
}


.beta-label {
  position: absolute;
  right: 10px;
  padding: 0px 7px;
  background-color: #12fa4ceb;
  color: #000000;
  font-size: 1em;
  font-weight: normal;
  border-radius: 2em;
  cursor: pointer;
  white-space: nowrap;
  line-height: 18px;
  display: inline-block;
}

    
.beta-label::after {
  content: 'This is in beta currently';
  display: none;
  position: absolute;
  right: 0;
  background-color: #19d4fa;
  color: #000000;
  padding: 2px 10px;
  border-radius: 2em;
  white-space: nowrap;
  font-size: 1em;
  z-index: 1000;
}

.beta-label:hover::after {
  display: inline-block;
}

/* Media query for header on mobile devices */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }
  .beta-label {
    
    font-size: 1em;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .beta-label::after {
    content: 'This is in beta currently';
    display: none;
    border-radius: 15px;
    top: 0px;
    white-space: nowrap;
    font-size: 1em;
    z-index: 1000;
  }
  
  .beta-label:hover::after {
    display: block;
  }
  
}
