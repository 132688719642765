/* CareerOptions.css */

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.career-options-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(to right, #4A148C, #ff0101);
  min-height: 100vh;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 1370px;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.career-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}




/* Career Details Styles */
.career-details {
  max-width: 1375px;
  margin: 0 auto;
  padding: 20px;
}

.back-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.career-card {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.career-card-header {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
}

.career-card-header h2 {
  color: #FFA500;
  font-size: 2rem;
  margin: 0;
}

.career-card-content {
  padding: 20px;
}

.career-details h3 {
  color: #FF6347;
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.career-details p, .career-details li {
  font-size: 1.1rem;
  line-height: 1.6;
}

.career-details ul {
  padding-left: 20px;
}

.career-details li {
  margin-bottom: 5px;
}

@media (max-width: 1200px) {
  .career-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .career-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .career-options-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(to right, #4A148C, #ff0101);
    min-height: 100vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;
  }
}
@media (max-width: 600px) {
  .career-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .career-options-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(to right, #4A148C, #ff0101);
    min-height: 100vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;
  }
}

@media (max-width: 480px) {
  .career-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .career-options-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(to right, #4A148C, #ff0101);
    min-height: 100vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;
  }
  
}
